import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Card, CardBody, Button, FormGroup, Label, Input } from 'reactstrap'
import Table from '../Table'
// import { courseList } from 'Views/Dashboard/Views/Meta/constants/utils'
import Select from 'react-select'
import { searchByCourse } from 'Views/Dashboard/Views/Enroll-Form/api'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import { CSVLink } from 'react-csv'
import './styles.scss'

const EduflexUsersData = ({
  heading = '',
  tableData = [],
  tableData2 = [],
  tableHeading = [],
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  rowData,
  setRowData,
  handleAllExportToCSV,
  selectedLimit,
  handleConfirmClick,
  handleStartDateChange,
  startDate,
  endDate,
  handleEndDateChange,
  selectUserData,
  handleSelectUsersListChange,
}) => {
  const getTime = (date) => {
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString();
    return `${dateString} ${timeString}`;
  }

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        const fDate = new Date(el.createdAt)
        var o = { ...el, createdAt: getTime(fDate) }//, 'courseDetails.date': formatedDateString
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }

  }, [tableData])

  const styles = {
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'red'
  }
  function isDateRangeValid(startDate, endDate) {
    // Convert date strings to Date objects
    let dateA = new Date(startDate);
    let dateB = new Date(endDate);
    let isALessThanB = dateA <= dateB;
    return isALessThanB
  }

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={10}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>{heading}</h3>
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>

            </Row>
            <Row>
              <Col xs={3}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <Col xs="auto">
                    <div>
                      <DatePicker
                        onChange={handleStartDateChange}
                        value={startDate}
                        clearIcon={null}
                      />
                      <DatePicker
                        onChange={handleEndDateChange}
                        value={endDate}
                        clearIcon={null}
                      />
                      <br />
                      {!isDateRangeValid(startDate, endDate) && <span style={{ color: 'red' }}>*Select a valid date range</span>}

                    </div>
                  </Col>
                  <Col xs={6} className='users-col-inp'>
                    <FormGroup>
                      <Select
                        isMulti={false}
                        value={selectedLimit}
                        options={selectUserData}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Course"
                      onChange={handleSelectUsersListChange}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs="auto" className='users-col-inp'>
                    <FormGroup>
                      <button className='export-btn' onClick={() => handleConfirmClick()} disabled={isDateRangeValid(startDate, endDate) ? '' : 'true'}>
                        Apply Filters</button>
                    </FormGroup>
                  </Col>
                  {tableData.length > 0 &&
                    <Col xs="auto" className='users-col-inp'>
                      <FormGroup>
                        <CSVLink
                          data={handleAllExportToCSV()}

                          filename={'eduflex_users.csv'}
                          className="export-csv-btn"
                        >
                          <button className='export-btn'>Export to CSV</button>
                        </CSVLink>

                      </FormGroup>
                    </Col>}
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default EduflexUsersData
