// import EditForm from '../Views/EditForm'
// import react from 'react'

import AllContactFormUsers from "../Views/All-signed-users";
import AllSignedUsers from "../Views/All-signed-users";

export const ContactUsersRoutes = [

  {
    Component: AllContactFormUsers,
    path: '/all',
    exact: true,
    id: 'dashboardEnrollFormAll',
    routePath: '/dashboard/contact-form-users/all',
    name: 'All Users'
  },


]
