import react, { useState, useEffect } from 'react'
import { EduflexUsersTableHeading } from '../../Constants'
import { getAllSignedCourses, getAllSignedUsers, searchByCourse } from '../../api'
import EduflexUsersData from 'Components/EduflexUsersCard'



const AllEduFlexUsers = () => {
  const [dataTags, setDataTags] = useState([])
  const [selectUserData, setSelectUserData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [paginationValueTags, setPaginationValueTags] = useState({
    currentPageTags: 1,
    totalSizeTags: 20,
  })
  const { currentPageTags, totalSizeTags } = paginationValueTags
  const [rowData, setRowData] = useState([])

  const [selectUserOption, setSelectUserOption] = useState([])
  const [selectedLimit, setSelectedLimit] = useState([])

  const getEduflexUsers = async (queryParams) => {
    try {
      let payload = {};
      if (startDate) {
        payload['startDate'] = formatDate(startDate).slice(0,10)
      }
      else {//if there is no date
        const startOfDay = new Date(); // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['startDate'] = isoStringStart.slice(0,10)
      }

      if (endDate) {
        payload['endDate'] = formatEndDate(endDate).slice(0,10)
      }
      else {//if there is no date
        const endOfDay = new Date(); // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['endDate'] = isoStringEnd.slice(0,10)
      }
      if (selectedLimit) {
        payload['course'] = selectedLimit.value
      }
      // payload['page'] = queryParams?.page
      // payload['limit'] = queryParams?.limit
      setIsLoading(true)
      const { data } = await getAllSignedUsers(payload)
      // setPaginationValueTags({
      //   currentPageTags: queryParams.page,
      //   totalSizeTags: data.count,
      // })
      setDataTags(data.data?.students)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  const handleStartDateChange = (date) => {

    setStartDate(date)
  }

  const handleEndDateChange = (date) => {
    setEndDate(date)
  }

  const formatDate = (date) => {
    const iso8601Regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?)$/;
    // Test the input string against the regular expression
    if (iso8601Regex.test(date)) {
      return date
    }
    else {
      return date.toISOString()//.slice(0, 10)}
    }
  }
  const formatEndDate = (date) => {
    const iso8601Regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?)$/;
    // Test the input string against the regular expression
    if (iso8601Regex.test(date)) {
      return date
    }
    else {
      const endOfDay = date;
      endOfDay.setHours(23, 59, 59, 999);
      return endOfDay.toISOString()//.slice(0, 10)}
    }
  }

  useEffect(async (selectedLimit) => {
    const startOfDay = new Date(); // Create a new Date object with the current date and time
    startOfDay.setHours(0, 0, 0, 1);
    const isoStringStart = startOfDay.toISOString();
    setStartDate(isoStringStart)

    const endOfDay = new Date(); // Create a new Date object with the current date and time
    endOfDay.setHours(23, 59, 59, 999);
    const isoStringEnd = endOfDay.toISOString();
    setEndDate(isoStringEnd)
    getEduflexUsers({ page: 1, limit: selectedLimit ? selectedLimit : 10 })
    getCoursesList()

  }, [])

  const getCoursesList = async () => {

    const { data } = await getAllSignedCourses({})
    let coursesList = data?.data?.courses
    let coursesDropdown = coursesList.map(item => ({
      value: item.id,
      label: item.name
    }));
    setSelectUserData(coursesDropdown)

  }
  const handleConfirmClick = async () => {
    getEduflexUsers({ page: 1, limit: selectedLimit ? selectedLimit : 10 })
  }



  const handleTablePageChangeTags = (type, { page, selectedCourse }) => {
    getEduflexUsers({ page, limit: selectedCourse ? selectedCourse : 10 })
    setPaginationValueTags({
      ...paginationValueTags,
      currentPageCat: page,
    })
  }

  const handleAllExportToCSV = () => {
    const csvDataAll = rowData.map((item) => ({
      Name: item.firstname,
      Course: item.course,
      Email: item.email,
      Phone: item.mob ? `=""${item.mob}""` : '',
      Gender: item.gender,
      DOB: item.dob ? `=""${item.dob}""` : '',
      // State: item.addr.state,
      City: item.addr.city,
      'Fee Status': item.fee_status,
      // CourseMode: item.courseDetails.mode,
      // CourseName: item.courseDetails.courseName,
      // 'Created At': `=""${item.createdAt}""`,
      // Payment: item.isPaid
    })
    )

    return csvDataAll
  }

  const handleSelectUsersListChange = (selectedOption) => {
    setSelectedLimit(selectedOption)
  }

  return (
    <>
      <EduflexUsersData
        heading="Eduflex Users"
        tableData={dataTags}
        tableHeading={EduflexUsersTableHeading}
        handleTablePageChange={handleTablePageChangeTags}
        page={currentPageTags}
        sizePerPage={20}
        totalSize={totalSizeTags}
        isLoading={isLoading}
        rowData={rowData}
        setRowData={setRowData}
        handleConfirmClick={handleConfirmClick}
        startDate={startDate}
        endDate={endDate}
        handleEndDateChange={handleEndDateChange}
        handleStartDateChange={handleStartDateChange}
        handleAllExportToCSV={handleAllExportToCSV}
        selectedLimit={selectedLimit}
        selectUserData={selectUserData}
        handleSelectUsersListChange={handleSelectUsersListChange}
      />
    </>
  )
}

export default AllEduFlexUsers
