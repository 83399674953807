export const RoomsTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Room No.',
    dataField: 'roomNo',
  },
  {
    text: 'Total Beds',
    dataField: 'numberOfBeds',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },

]
export const AvailableRoomsTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Room No.',
    dataField: 'room.roomNo',
  },
  {
    text: 'Bed',
    dataField: 'bedNo',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
  {
    text: '',
    dataField: '',
  },
]
export const BookingsHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'customer.name',
  },
  {
    text: 'Contact',
    dataField: 'customer.contactNo',
  },
  {
    text: 'Room No.',
    dataField: 'room.roomNo',
  },
  {
    text: 'Bed',
    dataField: 'bed.bedNo',
  },
  {
    text: 'Price',
    dataField: 'price',
  },
  {
    text: 'Check-In',
    dataField: 'userCheckInDate',
  },
  {
    text: 'Check-Out',
    dataField: 'checkOutDate',
  },
  {
    text: 'Status',
    dataField: 'status',
  },

]
export const IntercatedUsersHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'name',
  },
  {
    text: 'Contact No.',
    dataField: 'contactNo',
  },
  {
    text: 'Email',
    dataField: 'email',
  },
  {
    text: 'City',
    dataField: 'city',
  },
  {
    text: 'Course',
    dataField: 'courseName',
  },
  {
    text: 'Created On',
    dataField: 'createdAt',
  }
]
export const AdCourseTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'customer.name',
  },
  {
    text: 'Contact',
    dataField: 'customer.contactNo',
  },
  {
    text: 'Email',
    dataField: 'customer.email',
  },
  {
    text: 'City',
    dataField: 'customer.city',
  },
  {
    text: 'Course',
    dataField: 'courseSubscriptions.course',
  },
  {
    text: 'Course Date',
    dataField: 'courseSubscriptions.courseDate',
  },

  {
    text: 'Course Timing',
    dataField: 'courseSubscriptions.courseTimings',
  },
  {
    text: 'Mode',
    dataField: 'courseSubscriptions.mode',
  },
  {
    text: 'Amount',
    dataField: 'amount',
  },

  {
    text: 'Payment',
    dataField: 'status',
  },

  {
    text: 'Created At',
    dataField: 'createdAt',
  },
  {
    text: 'Room',
    dataField: 'roomBooking.status',
  },
  {
    text: 'Bed',
    dataField: 'roomBooking.bed.bedNo',
  },
  {
    text: 'Room Det',
    dataField: 'roomBooking',
  }


  // {
  //   text:'image URL',
  //   dataField:'imageUrl',
  // },
  // {
  //   text: 'Page URL',
  //   dataField: 'pdfUrl',
  // },
]

export const creatingStates = {
  UPLOADING_IMAGE: 1,
  UPLOADING_MUSIC: 2,
  CREATING_MUSIC: 3,
  UPLOAD_COMPLETE: 4,
}