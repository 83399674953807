
import { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { configUpdate, getConfig, uploadUsers } from '../../api'
import Papa from 'papaparse'; // Import PapaParse
import { CSVLink } from 'react-csv'
import { Row, Col, Card, CardBody, Button, FormGroup, Label, Input, Modal, ModalBody } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'

const WebPush = ({ showModal }) => {

  const handleSubmit = async () => {
    try {
      const response = await configUpdate(configForm);
      setShowAlert(true)
    }
    catch (error) {
      console.log(error);
    }

  }
  const [showAlert, setShowAlert] = useState(false);
  const [configForm, setConfigForm] = useState({ titleText: '', bodyText: '', okButtonText: '', rejectButtonText: '', isEnabled: true })

  const handelInput = (e) => {
    // Regular expression to allow only numbers or empty string
    const isValid = /^[a-zA-Z0-9 ?!.]*$/.test(e.target.value); // Matches empty or numbers only
    if (isValid || e.target.value === '') {
      setConfigForm({
        ...configForm,
        [e.target.name]: e.target.value,
      });
    }
  }

  const isValidForm = (configForm) => {
    const regEx = /^[a-zA-Z0-9 ?!.]+$/
    if (regEx.test(configForm.titleText) && regEx.test(configForm.okButtonText) && regEx.test(configForm.bodyText) && regEx.test(configForm.rejectButtonText)) { return false }
    else {
      return true
    }
  }
  const getConfigData = async () => {
    const response = await getConfig();
    if (response?.data?.data) {
      setConfigForm(response?.data?.data)
    }
  }
  useEffect(() => {
    getConfigData()
  }, [])

  return (
    <>
      {showAlert &&
        <SweetAlert
          title="Updated"
          // warning
          // showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Close"
          reverseButtons={true}
          onConfirm={() => setShowAlert(false)}
        ></SweetAlert>
      }
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">Update Web Push</h3>
                  </div>
                </Col>
              </Row>
              <AvForm className="form-horizontal" >
                <Col>
                  <Input
                    type="checkbox"
                    className="custom-control-input"
                    name="useSameThumbnail"
                    checked={configForm.isEnabled}
                    onChange={(e) => { setConfigForm({ ...configForm, isEnabled: e.target.checked }) }}
                    id="useSameThumbnail"
                  />
                  <Label
                    className="custom-control-label"
                    htmlFor="useSameThumbnail"
                  >
                    &nbsp; Enable Web Push
                  </Label>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="title">Title<span className='red'>*</span></Label>
                    <AvField
                      name="titleText"
                      placeholder="Enter title text"
                      value={configForm.titleText}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{
                        required: { value: true, errorMessage: "Title is required" },
                        pattern: { value: '^[a-zA-Z0-9 ?!.]*$', errorMessage: 'Only letters and numbers are allowed.' }
                      }}
                      id="title"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="body">Body<span className='red'>*</span></Label>
                    <AvField
                      name="bodyText"
                      placeholder="Enter body text"
                      value={configForm.bodyText}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Body name is required"
                      className="form-control"
                      validate={{
                        required: { value: true, errorMessage: "Title is required" },
                        pattern: { value: '^[a-zA-Z0-9 ?!.]*$', errorMessage: 'Only letters and numbers are allowed.' }
                      }}
                      id="body"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="success">Success Button<span className='red'>*</span></Label>
                    <AvField
                      name="okButtonText"
                      placeholder="Enter success button text"
                      value={configForm.okButtonText}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Success Button is required"
                      className="form-control"
                      validate={{
                        required: { value: true, errorMessage: "Title is required" },
                        pattern: { value: '^[a-zA-Z0-9 ?!.]*$', errorMessage: 'Only letters and numbers are allowed.' }
                      }}
                      id="success"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="reject">Reject Button<span className='red'>*</span></Label>
                    <AvField
                      name="rejectButtonText"
                      placeholder="Enter reject button text"
                      value={configForm.rejectButtonText}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Reject Button is required"
                      className="form-control"
                      validate={{
                        required: { value: true, errorMessage: "Title is required" },
                        pattern: { value: '^[a-zA-Z0-9 ?!.]*$', errorMessage: 'Only letters and numbers are allowed.' }
                      }}
                      id="reject"
                    />
                  </FormGroup>
                </Col>
              </AvForm>
              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={isValidForm(configForm)}
                onClick={() => handleSubmit()}
              >
                Update
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default WebPush
