// import EditForm from '../Views/EditForm'
// import react from 'react'

import AllSignedUsers from "../Views/All-signed-users";
import LoginList from "../Views/loggedin-users";

export const SignedUsersRoutes = [

  {
    Component: AllSignedUsers,
    path: '/all',
    exact: true,
    id: 'dashboardEnrollFormAll',
    routePath: '/dashboard/signed-users/all',
    name: 'All Signed Up Users'
  },
  {
    Component: LoginList,
    path: '/login-list',
    exact: true,
    id: 'dashboardEnrollFormAll',
    routePath: '/dashboard/signed-users/login-list',
    name: 'User Login list'
  },


]
