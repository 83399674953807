import { useEffect, useState } from 'react'
import Table from 'Components/Table'
import DatePicker from 'react-date-picker'
import { Row, Col, Card, CardBody, Button, FormGroup, Label, Input, Modal, ModalBody } from 'reactstrap'
import Select from 'react-select'
import { allocateRoom, saveRoom } from 'Views/Dashboard/Views/AdCourses/Api'

const AdBookings = ({
  tableData,
  tableHeading,
  handleTablePageChange,
  page,
  limit,
  handleEndDateChange,
  handleStartDateChange,
  startDate,
  endDate,
  handleConfirmClick,
  selectedLimit,
  selectUserData,
  handleSelectUsersListChange,
  roomList,
  relatedRoom,
  handleRelatedCoursesChange,
}) => {

  const [rowData, setRowData] = useState([])
  const [selectedUser, setSelectedUser] = useState()

  // RoomBookingStatus {
  //     Pending = 0,
  //     AllocationInProgress = 1,
  //     Allocated = 2,
  //     CheckIn = 3,
  //     Cancelled = 4,
  //     CheckedOut = 5
  // }

  const getTime = (date) => {
    const dateString = date.toLocaleDateString();
    // const timeString = date.toLocaleTimeString();
    return `${dateString}`;// ${timeString}
  }
  function isDateRangeValid(startDate, endDate) {
    // Convert date strings to Date objects
    let dateA = new Date(startDate);
    let dateB = new Date(endDate);
    let isALessThanB = dateA <= dateB;
    return isALessThanB
  }
  const getBookingStatus = (value) => {
    switch (value) {
      case 0:
        return 'Pending';
      case 1:
        return 'In Progress';
      case 2:
        return 'Allocated';
      case 3:
        return 'Checked-In';
      case 4:
        return 'Cancelled';
      case 5:
        return 'Checked-Out';
      default:
        return '';
      // Code to execute if no case matches
    }
  }


  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        const fDate = new Date(el.userCheckInDate)
        const lDate = new Date(el.checkOutDate)
        // console.log(fDate)
        var o = { ...el, status: getBookingStatus(el.status), userCheckInDate: (el.status == 3 || el.status == 5) ? getTime(fDate) : '-', checkOutDate: (el.status == 5) ? getTime(lDate) : '-' }
        o.sNo = i + 1 + (page ? page : 1) * 10 - 10
        return o
      })
      setRowData(result)
    }
    // eslint-disable-next-line
  }, [tableData])


  useEffect(() => {
    // tableHeading.push({
    //   text: 'Actions',
    //   // dataField: 'view',
    //   isDummyField: true,
    //   headerStyle: { textAlign: 'center', width: '10%' },
    //   formatter: viewContentFormatter,
    // })
    return () => {
      // tableHeading.pop()
      // tableHeading.pop()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>Booking List</h3>
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                <div>
                  <DatePicker
                    onChange={handleStartDateChange}
                    value={startDate}
                    clearIcon={null}
                  /><DatePicker
                    onChange={handleEndDateChange}
                    value={endDate}
                    clearIcon={null}
                  /><br />
                  {!isDateRangeValid(startDate, endDate) && <span style={{ color: 'red' }}>*Select a valid date range</span>}</div>
              </Col>
              <Col xs={2} className='course-col'>
                <FormGroup>
                  <Select
                    isMulti={false}
                    value={relatedRoom}
                    options={roomList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Search Room"
                    onChange={handleRelatedCoursesChange}
                  />
                </FormGroup>
              </Col>

              <Col xs={2} className='users-col'>
                <FormGroup>
                  <Select
                    isMulti={false}
                    value={selectedLimit}
                    options={selectUserData}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="No. of Rows"
                    onChange={handleSelectUsersListChange}
                  />
                </FormGroup>
              </Col>
              <Col xs="auto" className='users-col'>
                <FormGroup>
                  <button className='export-btn' onClick={() => handleConfirmClick()} disabled={isDateRangeValid(startDate, endDate) ? '' : 'true'}>Apply Filters</button>
                </FormGroup>
              </Col>

            </Row>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  >
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={limit}
              totalSize={10}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AdBookings
