import { useEffect, useState } from 'react'
import Table from 'Components/Table'
import DatePicker from 'react-date-picker'
import { Row, Col, Card, CardBody, Button, FormGroup, Label, Input, Modal, ModalBody } from 'reactstrap'
import Select from 'react-select'
import { allocateRoom, enrollUser, getAvailableRooms, getRoomAvailability } from 'Views/Dashboard/Views/AdCourses/Api'
import { CSVLink } from 'react-csv'
import { AvForm, AvField } from 'availity-reactstrap-validation'
const courseDetails = {
  '21Days': [
    {
      "date-lang": "1 Sep 2024 - English",
      "date": "2024-09-01",
      "endDate": "2024-09-22",
      "timing": ["07:00 am to 08:30 am", "06:30 pm to 08:00 pm"],
      "language": "English",
      "online": true,
      "onCampus": true,
      "courseFee": 2500
    },
    {
      "date-lang": "13 Oct 2024 - Hindi",
      "date": "2024-10-13",
      "endDate": "2024-11-03",
      "timing": ["07:00 am to 08:30 am", "06:30 pm to 08:00 pm"],
      "language": "Hindi",
      "online": true,
      "onCampus": true,
      "courseFee": 2500
    }
  ]
  ,
  '7Days': [
    {
      "date": '2024-08-31',
      "date-lang": "31 Aug 2024 - English",
      "endDate": "2024-09-06",
      "residential": true,
      "non-Residential": true,
      "courseFee": 10000,
      "roomFee": 6500
    }
  ],
  'ttc': [
    {
      "batch": 1,
      "date": '2024-09-02',
      "date-lang": "English: 2nd Sept to 30th Sept",
      "endDate": "2024-09-30",
      "online": true,
      "onCampus": false,
      "residential": true,
      "non-Residential": true,
      "onlineFee": 27500,
      "residentialFee": 60000,
      "nonResidentialFee": 33000,
    },
    {
      "batch": 2,
      "date": '2024-10-07',
      "date-lang": "English: 7th Oct to 30th Nov",
      "endDate": "2024-11-30",
      "online": true,
      "onCampus": false,
      "residential": true,
      "non-Residential": true,
      "onlineFee": 27500,
      "residentialFee": 90000,//room
      "nonResidentialFee": 33000,
    },
    {
      "batch": 3,
      "date": '2024-09-16',
      "date-lang": "English: 16th Sept to 9th Nov",
      "endDate": "2024-11-09",
      "online": true,
      "onCampus": false,
      "residential": false,
      "non-Residential": false,
      "onlineFee": 27500,
      "residentialFee": 0,
      "nonResidentialFee": 0,
    },
    {
      "batch": 4,
      "date": '2024-09-23',
      "date-lang": "Hindi: 23rd Sept to 16th Nov",
      "endDate": "2024-11-16",
      "online": true,
      "onCampus": false,
      "residential": false,
      "non-Residential": false,
      "onlineFee": 27500,
      "residentialFee": 0,
      "nonResidentialFee": 0,
    },
    {
      "batch": 5,
      "date": '2024-09-28',
      "date-lang": "English: 28th Sept to 15th Dec",
      "endDate": "2024-12-15",
      "online": true,
      "onCampus": false,
      "residential": false,
      "non-Residential": false,
      "onlineFee": 27500,
      "residentialFee": 0,
      "nonResidentialFee": 0,
    }
  ]
}

const AdCoursesCard = ({
  tableData,
  tableHeading,
  handleTablePageChange,
  page,
  limit,
  handleAction,
  handleEndDateChange,
  handleStartDateChange,
  startDate,
  endDate,
  handleConfirmClick,
  selectedLimit,
  selectUserData,
  handleSelectUsersListChange, contactNo, handleContactNoChange,
  courseList,
  relatedCourses,
  handleRelatedCoursesChange,
  batchList,
  relatedBatches, relatedTimings, timingList,
  handleRelatedBatchChange,
  handleRelatedBatchTiming,
  showModal,
  closeModal,
  roomOptions, selectedRoom, handleRomChange
}) => {
  const handelCutModal = () => {
    closeModal(false)
  }

  const checkInRoom = async () => {
    let payload = { room: null, status: 3 }
    allocateRoom(payload, selectedUser.roomBooking._id).then(res => {
      closeModal(true)
    }).catch(err => {
      console.log(err);
    })
    // closeModal(true)
  }

  const checkOutRoom = async () => {
    let payload = { room: null, status: 5 }
    allocateRoom(payload, selectedUser.roomBooking._id).then(res => {
      closeModal(true)
    }).catch(err => {
      console.log(err);
    })
    // closeModal(true)
  }

  const cancelRoom = async () => {
    let payload = { room: null, status: 4 }
    allocateRoom(payload, selectedUser.roomBooking._id).then(res => {
      closeModal(true)
    }).catch(err => {
      console.log(err);
    })
    // closeModal(true)
  }

  const confirmRoom = async () => {
    if (selectedRoom?.value) {
      let payload = { room: selectedRoom?.value, status: 2 }
      allocateRoom(payload, selectedUser.roomBooking._id).then(res => {
        closeModal(true)
      }).catch(err => {
        console.log(err);
      })
      // closeModal(true)
    }
  }

  const [rowData, setRowData] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [userForm, setUserForm] = useState({ name: '', contactNo: '', email: '', city: '', dialCode: 91 })
  const [selectedCourse, setSelectedCourse] = useState()//courseDetails?.['21Days']
  const [selectedBatch, setSelectedBatch] = useState()//courseDetails?.['21Days'][0]
  const [selectedTime, setSelectedTime] = useState()//courseDetails?.['21Days'][0]?.timing[0]
  const [selectedMode, setSelectedMode] = useState()//courseDetails?.['21Days'][0]?.online ? 2 : 1
  const [courseNameS, setCourseName] = useState()
  const [isCancel, setIsCancel] = useState(false)
  const [showTime, setShowTime] = useState(false)
  const [showRoom, setShowRoom] = useState(false)
  const [issRoomAvailable, setIsRoomAvailable] = useState(false)
  const [isCustomerModal, setCustomerModal] = useState(false)
  const [selectedRoomWi, setselectedRoomWi] = useState()
  const [roomList, setRoomList] = useState([])

  const getCourseAmount = (batch, mode) => {
    if (mode == 1) {
      return batch?.courseFee
    }
    else if (mode == 2) {
      if (batch?.courseFee) {
        return batch?.courseFee
      }
      else {
        return batch?.onlineFee
      }
    }
    else if (mode == 3) {
      if (batch?.courseFee) {
        return batch?.courseFee
      }
      else {
        return batch?.residentialFee
      }
    }
    else if (mode == 4) {
      if (batch?.courseFee) {
        return batch?.courseFee
      }
      else {
        return batch?.nonResidentialFee
      }
    }
  }

  const isEnrollFormValid = (userForm, selectedCourse, selectedBatch, selectedTime, selectedMode) => {
    let returnValue = false
    const { name, email, contactNo, city } = userForm;

    const isValidName = /^[a-zA-Z ]*$/.test(name);
    const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    const isValidContactNo = contactNo?.length === 10;
    const isValidCity = city.trim() !== '';

    if (!isValidName || !isValidEmail || !isValidContactNo || !isValidCity) {//checks bacic details
      returnValue = true
    }
    if (!selectedBatch) {//if the batch is notselectd
      returnValue = true
    }
    if (courseNameS == '21Days' && !selectedTime) {//for 21 days there should be time
      returnValue = true
    }
    if (!selectedMode) {//mode needs to select
      returnValue = true
    }
    if (selectedMode == '3' && !selectedRoomWi) {//if the mode is residential then Room should select
      returnValue = true
    }
    return returnValue
  }



  const createUser = async (userDetails, courseName, batch, time, mode) => {
    let payload = {
      "name": userDetails.name,
      "contactNo": userDetails.contactNo,
      "dialCode": userDetails.dialCode,
      "email": userDetails.email,
      "city": userDetails.city,
      "courseName": courseNameS,
      "courseDate": batch?.['date-lang'],
      "courseTimings": time ? time : null,
      "mode": mode,
      "amount": getCourseAmount(batch, selectedMode),
      "isRoomBooking": showRoom ? true : false,
      "room": showRoom ? selectedRoomWi.value : null,
      "checkInDate": showRoom ? batch?.date : null,
      "checkOutDate": showRoom ? batch?.endDate : null,
      "roomPrice": showRoom ? (selectedBatch?.roomFee ? selectedBatch?.roomFee : selectedBatch?.residentialFee) : null,
      "sharingType": showRoom ? 3 : null
    }

    const { data } = await enrollUser(payload);
    setCustomerModal(false)
    handleConfirmClick()
    setUserForm({ name: '', contactNo: '', email: '', city: '', dialCode: 91 })
    setselectedRoomWi()
  }

  // Handle form IP
  const handelInput = (e) => {
    setUserForm({
      ...userForm,
      [e.target.name]: e.target.value,
    })
  }

  const updateSelectedBatch = (event) => {
    setSelectedBatch(JSON.parse(event.target.value));
  }

  const updateCourse = (event) => {//Course dropdown change handling
    setShowRoom()
    if (event.target.value == '21Days') {// to show time dropdown
      setShowTime(true)
    }
    else {
      setShowTime(false)
    }
    setCourseName(event.target.value == 'ttc' ? '200 Hours TTC' : event.target.value)
    setSelectedCourse(courseDetails?.[event.target.value])// Set course and bacthc details
    setSelectedBatch(courseDetails?.[event.target.value])
  }

  const updateSelectedTime = (event) => {
    setSelectedTime(event.target.value);
  }
  const handleRoomChange = (event) => {
    setselectedRoomWi(event)
  }
  const updateSelectedMode = async (event) => {

    if (event.target.value == 3) {//residential
      setShowRoom(true);
      setselectedRoomWi()
      let payload = { checkInDate: selectedBatch?.date, checkOutDate: selectedBatch?.endDate, sharingType: 3 }
      let availability = await getRoomAvailability(payload)
      if (availability?.data?.triple) {//room is available
        setIsRoomAvailable(true)
        let response = await getAvailableRooms(payload)
        let roomArry = []
        response?.data?.forEach(ele => {
          roomArry.push({ label: ele.roomNo, value: ele._id })
        })
        setRoomList(roomArry)
      }
      else {
        setIsRoomAvailable(false)
      }

    }
    else {
      setShowRoom(false)
      setIsRoomAvailable(false)

    }
    setSelectedMode(event.target.value);
  }
  // RoomBookingStatus {
  //     Pending = 0,
  //     AllocationInProgress = 1,
  //     Allocated = 2,
  //     CheckIn = 3,
  //     Cancelled = 4,
  //     CheckedOut = 5
  // }
  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center', gap: '4px', display: 'flex', flexDirection: 'column' }} key={row?._id}>
        {row?.roomBooking?.status == 1 && <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => { setIsCancel(false); setSelectedUser(row); handleAction(row) }}
        >
          Allocate Room
        </Button>}
        {row?.roomBooking?.status == 2 && <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => { setIsCancel(true); setSelectedUser(row); handleAction(row) }}
        >
          Cancel
        </Button>}
        {row?.roomBooking?.status == 2 && <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => { setIsCancel(false); setSelectedUser(row); handleAction(row) }}
        >
          Check In
        </Button>}
        {row?.roomBooking?.status == 3 && <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => { setIsCancel(false); setSelectedUser(row); handleAction(row) }}
        >
          Check Out
        </Button>}

      </div>
    )
  }

  const getTime = (date) => {
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString();
    return `${dateString} ${timeString}`;
  }

  const getPaymentMode = (value) => {
    // return value == '1' ? 'On-Campus' : 'Online'
    switch (value) {
      case 1:
        return 'On-Campus';
      case 2:
        return 'Online';
      case 3:
        return 'Residential';
      case 4:
        return 'Non-Residential';
      // You can have as many case statements as needed
      default:
        return '';
      // Code to execute if no case matches
    }
  }
  function isDateRangeValid(startDate, endDate) {
    // Convert date strings to Date objects
    let dateA = new Date(startDate);
    let dateB = new Date(endDate);
    let isALessThanB = dateA <= dateB;
    return isALessThanB
  }
  const getPaymentStatus = (value) => {
    return value == '1' ? 'Pending' : 'Success'
  }

  const getRoomStatus = (value) => {
    console.log(value == 1);

    if (value == 1) {
      return 'In Progress'
    }
    else if (value == 2) {
      return 'Allocated'
    }
    else if (value == 3) {
      return 'Check-In'
    }
    else if (value == 4) {
      return 'Cancelled'
    }
    else if (value == 5) {
      return 'Check-Out'
    }
    else {
      return '-'
    }
    // switch (value) {
    //   case value?.roomBooking?.status == 1:
    //     return 'In Progress'
    //   case value?.roomBooking?.status == 2:
    //     return 'Allocated'
    //   case value?.roomBooking?.status == 3:
    //     return 'Check-In'
    //   case value?.roomBooking?.status == 4:
    //     return 'Cancelled'
    //   case value?.roomBooking?.status == 5:
    //     return 'Check-Out'
    //   default:
    //     return ''
    // }
  }
  const handleAllExportToCSV = () => {
    const csvDataAll = rowData.map((item) => ({
      Name: item?.customer?.name,
      Contact: `+${item?.customer?.dialCode} ${item?.customer?.contactNo}`,
      Email: item?.customer?.email,
      City: item?.customer?.city,
      Course: item?.courseName,
      'Course Date': item?.courseDate,
      'Course Timing': item?.courseTimings,
      'Mode': getPaymentMode(item.courseSubscriptions?.mode),
      'Amount': item?.amount,
      'Payment': item.status,
      'Created At': item?.courseSubscriptions?.createdAt,
      'Room': getRoomStatus(item.roomBooking?.status)
    })
    )

    return csvDataAll
  }
  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        const fDate = new Date(el.createdAt)
        // console.log(fDate)
        var o = {
          ...el, createdAt: getTime(fDate), status: getPaymentStatus(el.status), 'courseSubscriptions.mode': getPaymentMode(el.courseSubscriptions?.mode), 'roomBooking.status': getRoomStatus(el.roomBooking?.status),
          'customer.contactNo': `+${el?.customer?.dialCode} ${el?.customer?.contactNo}`
        }
        o.sNo = i + 1 + (page?page:1) * 10 - 10
        return o
      })
      setRowData(result)
    }
    // eslint-disable-next-line
  }, [tableData])


  useEffect(() => {
    tableHeading.pop()//remove room details form, using for data handling
    tableHeading.push({
      text: 'Actions',
      // dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: viewContentFormatter,
    })
    return () => {
      // tableHeading.pop()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Row>
      <Modal
        isOpen={showModal}
        size={'lg'}
        className='ImageUpoader-modal'
      >
        <ModalBody>
          <div className="closeButton">
            <button className='closemodal' onClick={handelCutModal}>X</button>
            {/* <AllImages showAllImagesModal={ showAllImagesModal } /> */}
            <h4>Customer Info</h4>
          </div>
          {/* <div>Available rooms</div> */}
          <Row>
            <Col lg={6}><b>Name: </b> {selectedUser?.customer?.name}</Col>
            <Col lg={6}><b>Contact No.: </b> +{selectedUser?.customer?.dialCode} {selectedUser?.customer?.contactNo}</Col>
          </Row>
          <Row>
            <Col lg={6}><b>Course: </b> {selectedUser?.courseName}</Col>
            <Col lg={6}><b>Course Date: </b> {selectedUser?.courseDate}</Col>
          </Row>
          <Row>
            <Col lg={6}><b>Amount: </b> {selectedUser?.amount}</Col>
            <Col lg={6}><b>Sharing type: </b> {selectedUser?.bookingDetails?.sharingType}</Col>
          </Row>
          {/* <Row>
            <Col lg={6}><b>Check-In: </b> {selectedUser?.roomBooking?.checkInDate.slice(0, 10)}</Col>
            <Col lg={6}><b>Check-Out: </b> {selectedUser?.roomBooking?.checkOutDate.slice(0, 10)}</Col>
          </Row>  */}
          <br />
          {selectedUser?.roomBooking?.status == 1 &&
            <Row>
              <Col lg={6}>
                <Select
                  isMulti={false}
                  value={selectedRoom}
                  options={roomOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Search Room"
                  onChange={handleRomChange}
                />
              </Col>
              <Col lg='auto'>
                <button className='export-btn' onClick={confirmRoom}>Allocate Room</button></Col>
            </Row>}

          {/* Check In Action */}
          {(selectedUser?.roomBooking?.status == 2 && !isCancel) && <button className='export-btn' onClick={checkInRoom}>Confirm Check In</button>}

          {/* Cancel room */}
          {(selectedUser?.roomBooking?.status == 2 && isCancel) && <button className='export-btn' onClick={cancelRoom}>Cancel Room</button>}

          {/* Checkout room */}
          {selectedUser?.roomBooking?.status == 3 && <button className='export-btn' onClick={checkOutRoom}>Confirm Check Out</button>}
        </ModalBody>
      </Modal>

      {/* Create a customer */}
      <Modal
        isOpen={isCustomerModal}
        size={'lg'}
        className='ImageUpoader-modal'
      >
        <ModalBody>
          <div className="closeButton">
            <button className='closemodal' onClick={() => setCustomerModal(false)}>X</button>
            <h4>Enroll User</h4>
          </div> <br />
          <AvForm className="form-horizontal" >
            {/* onValidSubmit={handleSubmit} */}
            {/* name & contact */}
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="numberOfUsers">Name<span className='red'>*</span></Label>
                  <AvField
                    name="name"
                    placeholder="Enter name"
                    value={userForm.name}
                    onChange={handelInput}
                    type="text"
                    errorMessage="Name is required"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="name"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="numberOfUsers">Mobile Number<span className='red'>*</span></Label>
                  <AvField
                    name="contactNo"
                    placeholder="Enter mobile number"
                    value={userForm.contactNo}
                    onChange={handelInput}
                    type="number"
                    errorMessage="Mobile number is required"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="contactNo"
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* Emai & City */}
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="numberOfUsers">Email<span className='red'>*</span></Label>
                  <AvField
                    name="email"
                    placeholder="Enter email"
                    value={userForm.email}
                    onChange={handelInput}
                    type="email"
                    errorMessage="Email is required"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="email"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="numberOfUsers">City<span className='red'>*</span></Label>
                  <AvField
                    name="city"
                    placeholder="Enter city name"
                    value={userForm.city}
                    onChange={handelInput}
                    type="text"
                    errorMessage="City name is required"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="city"
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* Course Type */}
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="couponTypeSelect">Course<span className='red'>*</span></Label>
                  <Input
                    name="course"
                    placeholder="Select Course"
                    type="select"
                    className="form-control"
                    // value={couponType}
                    // onChange={handelInput}
                    errorMessage="Course required"
                    validate={{ required: { value: true } }}
                    id="course"
                    onChange={updateCourse}
                  >
                    <option value="">
                      Select Course
                    </option>
                    <option value="21Days" >21-Day Yoga Challenge</option>
                    <option value="7Days" >7 Day Yoga Challenge</option>
                    <option value="ttc" >200 Hours TTC</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="couponTypeSelect">Course Date<span className='red'>*</span></Label>
                  <Input
                    name="course"
                    placeholder="Select Course Date"
                    type="select"
                    className="form-control"
                    // value={selectedCourseVal}
                    onChange={updateSelectedBatch}
                    errorMessage="Course required"
                    validate={{ required: { value: true } }}
                    id="course">
                    <option value="" >Select Course Date</option>
                    {selectedCourse?.map((course, index) => (
                      <option value={JSON.stringify(course)} key={index}>
                        {course?.['date-lang']}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {showTime && <Col>
                <FormGroup>
                  <Label htmlFor="couponTypeSelect">Course Timing<span className='red'>*</span></Label>
                  <Input
                    name="course"
                    placeholder="Select Course Timing"
                    type="select"
                    className="form-control"
                    // value={couponType}
                    onChange={updateSelectedTime}
                    errorMessage="Timing required"
                    validate={{ required: { value: true } }}
                    id="course">
                    <option value="" >Select Course Timing</option>
                    {selectedBatch?.timing?.map((timing, index) => (
                      <option value={timing} key={index}>
                        {timing}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>}

              <Col>
                <FormGroup>
                  <Label htmlFor="couponTypeSelect">Course Mode<span className='red'>*</span></Label>
                  <Input
                    name="courseMode"
                    placeholder="Select Course Mode"
                    type="select"
                    className="form-control"
                    // value={couponType}
                    onChange={updateSelectedMode}
                    errorMessage="Mode required"
                    validate={{ required: { value: true } }}
                    id="courseMode">
                    <option value="" >Select Course Mode</option>
                    {selectedBatch?.online && <option value="2" >
                      Online</option>}
                    {selectedBatch?.onCampus && <option value="1" >
                      On-Campus</option>}
                    {selectedBatch?.['non-Residential'] && <option value="4" >
                      Non-Residential</option>}
                    {selectedBatch?.residential && <option value="3" >
                      Residential</option>}
                  </Input>
                </FormGroup>
              </Col>
              {selectedMode=='3' && <Col>
                <FormGroup>
                  <Label htmlFor="couponTypeSelect">Select Room<span className='red'>*</span></Label>
                  {/* <Input
                    name="course"
                    placeholder="Select Room"
                    type="select"
                    className="form-control"
                    // onChange={updateSelectedTime}
                    errorMessage="Timing required"
                    validate={{ required: { value: true } }}
                    id="course">
                    <option value="" >Select Course Timing</option>
                    {selectedBatch?.timing?.map((timing, index) => (
                      <option value={timing} key={index}>
                        {timing}</option>
                    ))}
                  </Input> */}
                  <Select
                    isMulti={false}
                    value={selectedRoomWi}
                    options={roomList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Room"
                    onChange={handleRoomChange}
                  />
                  {!issRoomAvailable && <span className='red'>*All rooms are allocated or there are pending bookings in campaign enrolled users.</span>}
                </FormGroup>
              </Col>}
            </Row>
            {(selectedBatch && selectedBatch?.courseFee) && <Row>
              <Col>
                <div style={{ border: '1px solid black', borderRadius: '10px', padding: '6px', textAlign: 'center', fontSize: '1rem', margin: '12px 0' }}>Total Price:
                  <b>{showRoom ? (selectedBatch?.courseFee + selectedBatch?.roomFee) : selectedBatch?.courseFee}</b></div>
              </Col>
            </Row>}
            {(selectedBatch?.onlineFee) && <Row>
              <Col>
                <div style={{ border: '1px solid black', borderRadius: '10px', padding: '6px', textAlign: 'center', fontSize: '1rem', margin: '12px 0' }}>Total Price:
                  <b>{selectedMode == 2 && selectedBatch?.onlineFee}{selectedMode == 4 && selectedBatch?.nonResidentialFee}{selectedMode == 3 && selectedBatch?.residentialFee}</b></div>
              </Col>
            </Row>}

            <Col className='users-col'>
              <div style={{ textAlign: 'center' }}>
                <button className='export-btn' onClick={() => createUser(userForm, selectedCourse, selectedBatch, selectedTime, selectedMode)} disabled={isEnrollFormValid(userForm, selectedCourse, selectedBatch, selectedTime, selectedMode)}>Enroll User</button>
              </div>
            </Col>
          </AvForm>
        </ModalBody>
      </Modal>

      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>Campaign Enrolled Users</h3>
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="auto">
                <div>
                  <DatePicker
                    onChange={handleStartDateChange}
                    value={startDate}
                    clearIcon={null}
                  /><DatePicker
                    onChange={handleEndDateChange}
                    value={endDate}
                    clearIcon={null}
                  />
                  <br />
                  {!isDateRangeValid(startDate, endDate) && <span style={{ color: 'red' }}>*Select a valid date range</span>}</div>
              </Col>
              <Col xs="auto">
                {/* <Label htmlFor="orderNumber">Banner Image Order Number</Label> */}
                <Input
                  name="orderNumber"
                  placeholder="Enter Contact No."
                  type="number"
                  className="form-control"
                  value={contactNo}
                  onChange={handleContactNoChange}
                  // onChange={(e) => {
                  //   setFormData({ ...formData, orderNumber: e.target.value })
                  // }}
                  // validate={{ required: { value: true } }}
                  errorMessage="order num is required"
                  id="orderNumber"
                />
              </Col>

              <Col xs={2} className='course-col'>
                <FormGroup>
                  <Select
                    isMulti={false}
                    value={relatedCourses}
                    options={courseList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Search Courses"
                    onChange={handleRelatedCoursesChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={2} className='course-col'>
                <FormGroup>
                  <Select
                    isMulti={false}
                    value={relatedBatches}
                    options={batchList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Search Batch"
                    onChange={handleRelatedBatchChange}
                  />
                </FormGroup>
              </Col>
              <Col xs="auto" className='course-col'>
                <FormGroup>
                  <Select
                    isMulti={false}
                    value={relatedTimings}
                    options={timingList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Search Timing"
                    onChange={handleRelatedBatchTiming}
                  />
                </FormGroup>
              </Col>
              <Col xs={3} className='users-col'>
                <FormGroup>
                  <Select
                    isMulti={false}
                    value={selectedLimit}
                    options={selectUserData}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select No. of Users"
                    onChange={handleSelectUsersListChange}
                  />
                </FormGroup>
              </Col>
              <Col xs="auto" className='users-col'>
                <FormGroup>
                  <button className='export-btn' onClick={() => handleConfirmClick()} disabled={isDateRangeValid(startDate, endDate) ? '' : 'true'}>Apply Filters</button>
                </FormGroup>
              </Col>
              {rowData.length ? <Col xs="auto" className='users-col'>
                <FormGroup>
                  <CSVLink
                    data={handleAllExportToCSV()}
                    filename={'campaign_enrolled_users.csv'}
                    className="export-csv-btn"
                  >
                    <button className='export-btn'>Export to CSV</button>
                  </CSVLink>
                </FormGroup>
              </Col> : <></>}
              <Col xs="auto" className='users-col'>
                <FormGroup>
                  <button className='export-btn' onClick={() => {setCustomerModal(true);setselectedRoomWi();setSelectedBatch()}}>Enroll User</button>
                </FormGroup>
              </Col>

            </Row>
            {/* <br />
            <Row>
            </Row> */}
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  {/* <h3 className='m-0'>Published Content</h3> */}
                  <div
                    className='page-title-right'
                    style={{ display: 'flex', width: '20%', gap: '10px' }}
                  >
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={limit}
              totalSize={10}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AdCoursesCard
