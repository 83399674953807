import axios from 'axios'
import { usersEnrolledApiUrl, searchByForm, searchByDate, usersEnrolledApiUrlList, usersEnrolledCourses, userSignedList } from 'Constants/apiRoutes'
import { authBaseDomain, cmsBaseDomain } from 'Views/Dashboard/Constants/baseUrl'

const cmsBaseDomainn = `${cmsBaseDomain}v1`

// export const getAllEnrolledUserReq = ({ page, limit }) => {
//   return axios.get(`${cmsBaseDomainn}${usersEnrolledApiUrl}?page=${page}&limit=${limit}`)
// }
export const getAllEnrolledUserReq = ({ page, limit }, payload) => {
  return axios.post(`${cmsBaseDomainn}${usersEnrolledApiUrlList}?page=${page}&limit=${limit}`, payload)
}
export const getAllSignedUsers = ({ page, limit }, payload) => {
  return axios.post(`${authBaseDomain}${userSignedList}`, payload)
}
export const getAllLoggedUsers = ({ page, limit }, payload) => {
  return axios.post(`${authBaseDomain}v2/user/logs/list`, payload)
}
export const getAllEnrolledCourses = (payload) => {
  return axios.post(`${cmsBaseDomainn}${usersEnrolledCourses}`, payload)
}
// export const searchByCourse = ({ search, page, limit }) => {
//   return axios.post(`${cmsBaseDomainn}${searchByForm}?page=${page}&limit=${limit}`, search)
// }
export const searchByCourse = ({ search, page, limit }) => {
  return axios.post(`${cmsBaseDomainn}${usersEnrolledApiUrlList}?page=${page}&limit=${limit}`, search)
}
export const searchByDateData = (search) => {
  return axios.post(`${cmsBaseDomainn}${searchByDate}`, search)
}

export const searchByCoursePageLimit = ({ search, page, limit }) => {
  return axios.post(`${cmsBaseDomainn}${searchByForm}?page=${page}&limit=${limit}`, search)
}