import axios from 'axios'
import { authBaseDomain } from 'Views/Dashboard/Constants/baseUrl'

export const uploadUsers = (data) => {
  return axios.post(`${authBaseDomain}/v2/user/cleverTap/upload`, data)
}

export const configUpdate = (data) => {
  return axios.post(`${authBaseDomain}/v2/system-config/clevertap-webpush`, data)
}

export const getConfig = () => {
  return axios.get(`${authBaseDomain}/v2/system-config/clevertap-webpush`)
}