import { Route, Routes, Navigate } from 'react-router-dom'

import ClverTapRoutes from './constants/routes'

const CleverTap = () => {
  return (
    <>
      <Routes>
        {ClverTapRoutes.map(({ path, Component }) => (
          <Route path={path} element={<Component />} key={path} />
        ))}
        <Route
          path="*"
          element={
            <Navigate to={ClverTapRoutes[0]?.routePath || '/dashboard'} replace />
          }
        />
      </Routes>
    </>
      




  )


}

export default CleverTap




