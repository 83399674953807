import { authBaseDomain } from "Views/Dashboard/Constants/baseUrl"

const baseDomain = authBaseDomain
  // process.env.NODE_ENV === 'development'
	// 			  ? authBaseDomain
  // 	: authBaseDomain


const stage = '/v2'

export const loginAPI = '/auth/login' // POST	

export const getMe = '/auth/me' // GET Protected

// Users
export const userRoleApiUrl = '/user'

// Enrolled Users
export const usersEnrolledApiUrl = '/forms'
// Enrolled UsersList
export const usersEnrolledApiUrlList = '/forms/list'
export const userSignedList = 'v2/user/list'
export const usersEnrolledCourses = '/forms/courses/list'
//Upload Content
export const generateCred = '/alibaba/generateCred'


//Content
export const contentApi = '/content'

//Product
export const productApi = '/product'

//Search Product
export const searchProductApi = 'product/searchproduct'


//Category
export const categoryApi = '/category'

//tags
export const tagApi = '/tags'

// Coupons
export const CouponApi = '/coupon'

// Page
export const pageApi = '/page'

// Courses
export const coursesApi = '/course'  //GET

export const secureStsCredApi = '/ali/token'


export const secureStsVerifyApi = '/ali/token/verify'

// eslint-disable-next-line
export default `${baseDomain}${stage}`

//enrolled users Search

export const searchByForm = '/forms/searchByCourse'
export const searchByDate = '/forms/searchByDate'
