export const EduflexUsersTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'firstname',
  },
  {
    text: 'Course',
    dataField: 'course',
  },
  {
    text: 'Email',
    dataField: 'email',
  },
  {
    text: 'Phone',
    dataField: 'mob',
  },
  {
    text: 'Gender',
    dataField: 'gender',
  },
  {
    text: 'DOB',
    dataField: 'dob',
  },
  // {
  //   text: 'State',
  //   dataField: 'addr.state',
  // },
  {
    text: 'City',
    dataField: 'addr.city',
  },
  {
    text: 'Fee Status',
    dataField: 'fee_status',
  },
  // {
  //   text: 'Created On',
  //   dataField: 'createdAt',
  // },
]