export const ContactUsersTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'fullName',
  },
  {
    text: 'Email',
    dataField: 'email',
  },
  {
    text: 'Phone',
    dataField: 'contactNo',
  },
  {
    text: 'Comment',
    dataField: 'comment',
  },
  // {
  //   text: 'DOB',
  //   dataField: 'dob',
  // },
  // {
  //   text: 'State',
  //   dataField: 'addr.state',
  // },
  // {
  //   text: 'City',
  //   dataField: 'addr.city',
  // },
  // {
  //   text: 'Fee Status',
  //   dataField: 'fee_status',
  // },
  {
    text: 'Created On',
    dataField: 'createdAt',
  },
]