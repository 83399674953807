import axios from 'axios'
import { usersEnrolledApiUrl, searchByForm, searchByDate, usersEnrolledApiUrlList, usersEnrolledCourses, userSignedList } from 'Constants/apiRoutes'
import { authBaseDomain, cmsBaseDomain } from 'Views/Dashboard/Constants/baseUrl'

const cmsBaseDomainn = `${cmsBaseDomain}v1`


export const getAllSignedUsers = ({ page, limit }, payload) => {
  return axios.post(`${cmsBaseDomainn}/contactUs/list`, payload)
}

