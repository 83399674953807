// import EditForm from '../Views/EditForm'
// import react from 'react'

import AllSignedUsers from "../Views/All-signed-users";

export const EduflexUsersRoutes = [

  {
    Component : AllSignedUsers,
    path: '/all',
    exact: true,
    id: 'dashboardEnrollFormAll',
    routePath: '/dashboard/eduflex-users/all',
    name: 'All Users'
  },

  
]
