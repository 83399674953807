import react, { useState, useEffect } from 'react'
import { LoggedUsersTableHeading, } from '../../Constants'
import { getAllLoggedUsers, } from '../../api'
import LogedUsersData from 'Components/LoggedUsers'

const selectUserData = [{ label: 10, value: 10 },
{ label: 20, value: 20 },
{ label: 50, value: 50 },
{ label: 100, value: 100 },
{ label: 500, value: 500 },
{ label: 1000, value: 1000 },
{ label: 2000, value: 2000 },
{ label: 5000, value: 5000 },
  // { label: 10000, value: 10000 }, 
]


const LoginList = () => {
  const [dataTags, setDataTags] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [paginationValueTags, setPaginationValueTags] = useState({
    currentPageTags: 1,
    totalSizeTags: 20,
  })
  const { currentPageTags, totalSizeTags } = paginationValueTags
  const [rowData, setRowData] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectUserOption, setSelectUserOption] = useState([])
  const [selectedLimit, setSelectedLimit] = useState([])
  const [contactNo, setContactNo] = useState()
  const handleContactNoChange = (data) => {
    setContactNo(data.target.value)
  }
  const getCategoryData = async (queryParams) => {
    try {
      let payload = {};
      // if (contactNo) {
      //   payload['contactNo'] = contactNo
      // }
      if (startDate) {
        payload['startDate'] = formatDate(startDate)
      }
      else {//if there is no date
        const startOfDay = new Date(); // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['startDate'] = isoStringStart
      }

      if (endDate) {
        payload['endDate'] = formatEndDate(endDate)
      }
      else {//if there is no date
        const endOfDay = new Date(); // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['endDate'] = isoStringEnd
      }
      payload['page'] = queryParams?.page
      payload['limit'] = queryParams?.limit
      setIsLoading(true)
      const { data } = await getAllLoggedUsers(queryParams, payload)
      setPaginationValueTags({
        currentPageTags: queryParams.page,
        totalSizeTags: data.count,
      })
      setDataTags(data.data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  useEffect(async (selectedLimit) => {

    const startOfDay = new Date(); // Create a new Date object with the current date and time
    startOfDay.setHours(0, 0, 0, 1);
    const isoStringStart = startOfDay.toISOString();
    setStartDate(isoStringStart)

    const endOfDay = new Date(); // Create a new Date object with the current date and time
    endOfDay.setHours(23, 59, 59, 999);
    const isoStringEnd = endOfDay.toISOString();
    setEndDate(isoStringEnd)
    getCategoryData({ page: 1, limit: selectedLimit ? selectedLimit : 10 })

  }, [])

  const handleStartDateChange = (date) => {

    setStartDate(date)
  }

  const handleEndDateChange = (date) => {
    setEndDate(date)
  }
  const formatDate = (date) => {
    const iso8601Regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?)$/;
    // Test the input string against the regular expression
    if (iso8601Regex.test(date)) {
      return date
    }
    else {
      return date.toISOString()//.slice(0, 10)}
    }
  }

  const formatEndDate = (date) => {
    const iso8601Regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?)$/;
    // Test the input string against the regular expression
    if (iso8601Regex.test(date)) {
      return date
    }
    else {
      const endOfDay = date;
      endOfDay.setHours(23, 59, 59, 999);
      return endOfDay.toISOString()//.slice(0, 10)}
    }
  }

  const handleSelectUsersListChange = (selectedOption) => {
    setSelectedLimit(selectedOption)
  }


  const handleConfirmClick = async () => {
    const limitValue = selectedLimit ? selectedLimit.value : 10

    if (limitValue) {
      await getCategoryData({ page: 1, limit: limitValue })
    } else {
      await getCategoryData({ page: 1, limit: 10 })
    }
  }


  const handleTablePageChangeTags = (type, { page, selectedCourse }) => {
    getCategoryData({ page, limit: selectedCourse ? selectedCourse : 10 })
    setPaginationValueTags({
      ...paginationValueTags,
      currentPageCat: page,
    })
  }

  const handleAllExportToCSV = () => {
    const csvDataAll = rowData.map((item) => ({
      Name: item.user?.firstName ? `${item.user?.firstName} ${item.user?.lastName}` : '',
      Email: item.user?.email,
      Phone: item.user?.phoneNumber ? `${item.user.dialCode?.startsWith("+")?`${item.user.dialCode}`:`+${item.user.dialCode}`} ${item.user.phoneNumber}` : '',
      Gender: item.user?.gender,
      Role: item.user?.role,
      Country: item.user?.country,
      State: item.user?.state,
      City: item.user?.city,
      'Created On': item.createdAt ? `=""${item?.createdAt}""` : '',
      // Payment: item.isPaid
    })
    )

    return csvDataAll
  }



  return (
    <>
      <LogedUsersData
        heading="User Login list"
        tableData={dataTags}
        tableHeading={LoggedUsersTableHeading}
        handleTablePageChange={handleTablePageChangeTags}
        handleConfirmClick={handleConfirmClick}
        page={currentPageTags}
        sizePerPage={20}
        totalSize={totalSizeTags}
        isLoading={isLoading}
        rowData={rowData}
        setRowData={setRowData}
        startDate={startDate}
        endDate={endDate}
        handleEndDateChange={handleEndDateChange}
        handleStartDateChange={handleStartDateChange}
        handleAllExportToCSV={handleAllExportToCSV}
        selectUserData={selectUserData}
        handleSelectUsersListChange={handleSelectUsersListChange}
        selectedLimit={selectedLimit}
        contactNo={contactNo}
        handleContactNoChange={handleContactNoChange}
      />
    </>
  )
}

export default LoginList
