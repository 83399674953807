import axios from 'axios'
import { usersEnrolledApiUrl, searchByForm, searchByDate, usersEnrolledApiUrlList, usersEnrolledCourses, userSignedList } from 'Constants/apiRoutes'
import { authBaseDomain, cmsBaseDomain } from 'Views/Dashboard/Constants/baseUrl'

const cmsBaseDomainn = `${cmsBaseDomain}v1`


export const getAllSignedUsers = (payload) => {
  return axios.post(`${cmsBaseDomainn}/eduFlex/users/list`, payload)
}
export const getAllSignedCourses = () => {
  return axios.post(`${cmsBaseDomainn}/eduFlex/courses/list`, {})
}

