import { Route, Routes, Navigate } from 'react-router-dom'
import { ContactUsersRoutes } from './Constants/routes'


const ContactUsers = () => {
  return (
    <>
      <Routes>
        {ContactUsersRoutes.map(({ path, Component }) => (
          <Route path={path} element={<Component />} key={path} />
        ))}
        <Route
          path="*"
          element={
            <Navigate
              to={ContactUsersRoutes[0]?.routePath || '/dashboard'}
              replace
            />
          }
        />
      </Routes>
    </>
  )
}

export default ContactUsers
