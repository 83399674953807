export const SignedUsersTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'firstName',
  },
  {
    text: 'Email',
    dataField: 'email',
  },
  {
    text: 'Phone',
    dataField: 'phoneNumber',
  },
  {
    text: 'Gender',
    dataField: 'gender',
  },
  {
    text: 'Country',
    dataField: 'country',
  },
  {
    text: 'City',
    dataField: 'city',
  },
  {
    text: 'Created On',
    dataField: 'createdAt',
  },
  {
    text: 'Last Active On',
    dataField: 'lastActive',
  },
  // {
  //   text: 'Country',
  //   dataField: 'personalDetails.country',
  // },
  // {
  //   text: 'City',
  //   dataField: 'personalDetails.city',
  // },
  // {
  //   text: 'Nationality',
  //   dataField: 'personalDetails.nationality',
  // },
  // {
  //   text: 'Course Mode',
  //   dataField: 'courseDetails.mode',
  // },
  // {
  //   text: 'Course Name',
  //   dataField: 'courseDetails.courseName',
  // },
  // {
  //   text: 'Date/Time',
  //   dataField:'courseDetails.date',
  // },
  // {
  //   text: 'Payment',
  //   dataField: 'isPaid',
  // },
  // {
  //   text: 'Payment Date',
  //   dataField: 'createdAt',
  // },

]


export const LoggedUsersTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'user.firstName',
  },
  {
    text: 'Email',
    dataField: 'user.email',
  },
  {
    text: 'Phone',
    dataField: 'user.phoneNumber',
  },
  {
    text: 'Gender',
    dataField: 'user.gender',
  },
  {
    text: 'Role',
    dataField: 'user.role',
  },
  {
    text: 'Country',
    dataField: 'user.country',
  },
  {
    text: 'State',
    dataField: 'user.state',
  },
  {
    text: 'City',
    dataField: 'user.city',
  },
  {
    text: 'Created On',
    dataField: 'user.createdAt',
  }

]






// export const PublishedCourseTableHeading = [
//   {
//     text: 'S.No.',
//     dataField: 'sNo',
    
//   },
//   {
//     text: 'Course Name',
//     dataField: 'title',
//   },
//   {
//     text: 'Is Free',
//     dataField: 'isFree',
//   },
//   {
//     text: 'Course Details',
//     dataField: 'description',
//   }
// ]